<template>
  <div class="thanks-for-answer">
    <div class="thanks-for-answer__icon">
      <img v-if="!error" src="@/assets/images/thanks.svg" alt="" />
      <img v-else src="@/assets/images/error.svg" alt="" />
    </div>
    <div class="thanks-for-answer__title">{{ customTitle || $t("testNGames.ratingThanks") }}</div>
    <div v-show="(showLabel && this.autoClose) || customLabel" class="thanks-for-answer__label">
      {{ customLabel || $t("testNGames.ratingThanksLabel") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PollThanksForAnswer",
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    // Кастомное название, если оно необходимо
    customLabel: {
      type: String,
      default: null,
    },
    // Кастомный текст сообщения. Нужен для отображения
    customTitle: {
      type: String,
      default: null,
    },
    // Ошибка ли это, нужна для отображения грустного смайлика
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  watch: {
    slide(newVal, oldVal) {
      if (oldVal && newVal && newVal.id !== oldVal.id) {
        clearTimeout(this.timeout);
      }
    },
  },
  mounted() {
    if (this.autoClose) {
      this.timeout = setTimeout(() => {
        this.$emit("close");
      }, 3000);
    }
  },
};
</script>

<style scoped lang="less">
.thanks-for-answer {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(-50%);

  &__icon {
    margin-bottom: 30px;

    & path {
      fill: var(--base-color);
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 23px;
    font-weight: bold;
    color: #000000;
  }

  &__label {
    font-size: 13px;
    color: #7f7f7f;
  }
}
</style>
