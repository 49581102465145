<template>
  <div
    class="interactive"
    :class="[interactiveTheme.class, typePollScrollMod]"
    :style="interactiveTheme.styles"
  >
    <component :is="interactiveType" v-if="interactiveType" v-bind="componentProps"></component>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { polls, pollsInteractive } from "@/store/modules/store.namespaces";
import Poll from "@/components/common/polls/components/Poll";
import PollScores from "@/components/common/polls/components/PollScores";
import { POLL } from "@/constants/polls/poll-interactive-types";
import { interactive } from "@/constants/polls/polls-module-types";
import { INTERACTIVE_THEME } from "@/store/modules/polls/getter-types";

export default {
  components: {
    Poll,
    PollScores,
  },
  computed: {
    ...mapState(pollsInteractive, [
      "activePoll",
      "sendingStatus",
      "chain",
      "chainScore",
      "interactiveType",
    ]),
    ...mapGetters(polls, {
      interactiveTheme: INTERACTIVE_THEME,
    }),
    componentProps() {
      if (this.interactiveType === POLL) {
        return {
          sendingStatus: this.sendingStatus,
          pollChain: this.chain,
          poll: this.activePoll,
          moduleType: interactive,
        };
      }
      return {
        pollChain: this.chainScore,
        moduleType: interactive,
      };
    },
    typePollScrollMod() {
      return this.interactiveType === POLL
        ? [`interactive--${this.interactiveType && this.interactiveType.toLowerCase()}`]
        : "";
    },
  },
};
</script>

<style scoped lang="less">
@import url(~@/styles/_mixin.less);
.interactive {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 0 auto;
  background-color: white;

  .reset-scroll();

  &--poll {
    overflow-y: auto;
  }

  &.interactive--bg-dark {
    background-image: linear-gradient(154deg, #4b4b4b, #282828);
  }

  &.interactive--bg-custom {
    background: #fff no-repeat center;
    background-size: cover;
  }

  ::v-deep .poll-scores {
    height: 100%;
    padding: 0 50px;

    &__wrap {
      height: 100%;
    }

    @media (max-width: 768px) {
      padding: 0;
    }
  }
}

@media (max-width: 650px) {
  .interactive {
    padding: 0 15px;

    ::v-deep .poll-scores {
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
}
</style>
