<template>
  <div class="poll-wrapper" :class="{ 'poll-wrapper--show-thanks': isAnswered }">
    <component
      :is="pollComponent"
      v-if="!sendingStatus && Object.keys(poll).length"
      v-show="!isAnswered"
      :key="key"
      ref="pollComponent"
      :poll="poll"
      :poll-chain="pollChain"
      :module-type="moduleType"
      @error="showError"
      @clearError="clearErr"
    >
      <div slot="timer" class="timer-wrapper">
        <transition name="timer-fade">
          <test-timer
            :start-time-stamp="startTimeStamp"
            :timer-period="timerPeriod"
            :initial-time-in-seconds="initialTimeInSeconds"
            :common-timer="true"
            class="timer"
            @time-is-out="timeOut"
          />
        </transition>
      </div>
    </component>
    <poll-control-button
      v-if="!sendingStatus && !isAnswered && !userIsMonitor && Object.keys(poll).length"
      class="poll-wrapper__answer-button"
      :disabled="sendNewAnswerStatus"
      @click="postPollAnswer"
    >
      {{ $t("testNGames.answer") }}
    </poll-control-button>
    <loader v-if="sendingStatus" class="loader poll-wrapper__loader" />
    <div class="poll-wrapper__error">{{ errorMsg }}</div>
    <poll-thanks-for-answer
      v-if="(isAnswered && !isFromSlide) || !Object.keys(poll).length"
      :slide="slide"
      :custom-title="
        moduleType == 'interactive' && !Object.keys(poll).length ? $t('Tests.youPassed') : ''
      "
      :auto-close="moduleType != 'presentation'"
      @close="closeThanks"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Loader from "@/components/common/elements/Loader";
import * as pollTypes from "@/constants/polls/poll-types";
import { auth, polls } from "@/store/modules/store.namespaces";
import { SET_IS_BLOCK_CLOSE_POLL, SET_SENDING_STATUS } from "@/store/modules/polls/mutation-types";
import { SEND_POLL_ANSWER, FINISH_TEST, CLOSE_POLL } from "@/store/modules/polls/action-types";
import UserRoles from "@/constants/user/roles-const";
import PollControlButton from "./common/PollControlButton";
import InputPoll from "./poll/InputPoll";
import SimplePoll from "./poll/SimplePoll";
import RelationPoll from "./poll/RelationPoll";
import RelationImagesPoll from "./poll/RelationImagesPoll";
import ChooseWrong from "./poll/ChooseWrongPoll";
import RatingPoll from "./poll/RatingPoll";
import WordCloud from "./poll/WordCloudPoll";
import TestTimer from "./common/TestTimer";
import PollThanksForAnswer from "./PollThanksForAnswer";
import AnalogScale from "./poll/AnalogScale";
import GradientScale from "./poll/GradientScale";
import { interactive } from "@/constants/polls/polls-module-types";

const pollComponentsMap = {
  [pollTypes.closed]: "SimplePoll",
  [pollTypes.several]: "SimplePoll",
  [pollTypes.open]: "InputPoll",
  [pollTypes.relation]: "RelationPoll",
  [pollTypes.relationImage]: "RelationImagesPoll",
  [pollTypes.chooseWrong]: "ChooseWrong",
  [pollTypes.rating]: "RatingPoll",
  [pollTypes.wordCloud]: "WordCloud",
  [pollTypes.gradientScale]: "GradientScale",
  [pollTypes.analogScale]: "AnalogScale",
};

export default {
  name: "Poll",
  components: {
    SimplePoll,
    InputPoll,
    RelationPoll,
    RelationImagesPoll,
    ChooseWrong,
    RatingPoll,
    WordCloud,
    PollControlButton,
    Loader,
    TestTimer,
    PollThanksForAnswer,
    AnalogScale,
    GradientScale,
  },
  props: {
    isFromSlide: {
      type: Boolean,
      default: false,
    },
    slide: {
      type: Object,
      default: () => {},
    },
    pollChain: {
      type: Object,
      default: () => {},
    },
    sendingStatus: {
      type: Boolean,
      default: false,
    },
    poll: {
      type: [Object, Array],
      required: true,
    },
    moduleType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Сообщение ошибки
      errorMsg: "",
      // Пройден/закрыт ли тест
      isAnswered: false,
      isEmmitedEventFromThanks: false,
      timeForTest: 0,
      // Ключ для обновления компонента
      key: 0,
    };
  },
  computed: {
    ...mapState(polls, ["sendNewAnswerStatus"]),
    ...mapState(auth, ["user"]),
    pollComponent() {
      return pollComponentsMap[this.poll.type];
    },
    initialTimeInSeconds() {
      return this.timeForTest || this.pollChain.timerOut;
    },
    showResultsToClient() {
      return this.poll.show_results_to_client;
    },
    /**
     * Последний ли это вопрос
     *
     * @returns {boolean}
     */
    isLastPoll() {
      return !this.poll ||
        !this.lastPollId ||
        this.pollChain?.questionsNum > this.pollChain?.polls?.length
        ? false
        : this.lastPollId === this.poll?.id;
    },
    /**
     * ID последнего теста.
     *
     * @returns {null|number} - id последнего теста.
     */
    lastPollId() {
      if (!this.pollChain) return null;

      const { polls } = this.pollChain;
      if (!polls?.length) return null;

      return polls[polls.length - 1].id;
    },
    startTimeStamp() {
      return +this.pollChain?.time_started;
    },
    timerPeriod() {
      return +this.pollChain?.timer;
    },
    userIsMonitor() {
      return this.user.role === UserRoles.Screen;
    },
  },
  watch: {
    slide(newSlideQuiz, prevSlideQuiz) {
      if (prevSlideQuiz && newSlideQuiz.id !== prevSlideQuiz.id && this.isAnswered) {
        this.handleChangeSlide(prevSlideQuiz);
      }
    },
    // Отслеживание изменения вопросов.
    // Необходимо обновлять компонент при изменении вопроса.
    // Так, как могут возникнуть проблемы, при которых элемент оставит данные прошлого вопроса.
    poll: {
      handler() {
        this.key += 1;
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (!this.isEmmitedEventFromThanks && this.isAnswered) {
      this.sendAnswer();
    }
    this.setPollSendingStatus({
      type: this.moduleType,
      payload: false,
    });
  },
  methods: {
    ...mapMutations(polls, {
      setPollSendingStatus: SET_SENDING_STATUS,
      setIsBlockClosePoll: SET_IS_BLOCK_CLOSE_POLL,
    }),
    ...mapActions(polls, {
      setPollSendingStatus: SET_SENDING_STATUS,
      setIsBlockClosePoll: SET_IS_BLOCK_CLOSE_POLL,
      finishTest: FINISH_TEST,
      closePoll: CLOSE_POLL,
    }),
    /**
     * Метод очистки сообщения ошибки.
     *
     * @returns {void}
     */
    clearErr() {
      this.errorMsg = "";
    },
    /**
     * Метод показа сообщения ошибки.
     *
     * @param {string} error - текст ошибки
     * @returns {void}
     */
    showError(error) {
      this.errorMsg = error;
    },
    /**
     * Метод проверки ответа, является ли он последним.
     * Если он последний - отобразить окно пройденного теста/опроса
     *
     * @returns {void}
     */
    postPollAnswer() {
      if (this.isLastPoll && !this.$refs.pollComponent?.hasError && !this.showResultsToClient) {
        this.clearErr();
        this.isAnswered = true;
        this.setIsBlockClosePoll(true);

        if (this.slide) this.slide.isTestAnswered = true;

        this.sendAnswer();

        if (this.isFromSlide) {
          this.closeThanks();
        }
      } else {
        this.sendAnswer();
      }
    },
    /**
     * Метод отправки ответа
     *
     * @param {number} slideId - ID слайда, на котором находится данный тест.
     * @returns {void}
     */
    sendAnswer(slideId) {
      let currentSlideId = null;
      if (slideId && this.isFromSlide) currentSlideId = slideId;
      else if (this.slide && this.slide.id && this.isFromSlide) currentSlideId = this.slide.id;
      if (this.$refs.pollComponent)
        this.$refs.pollComponent.sendAnswer(SEND_POLL_ANSWER, this.isFromSlide, currentSlideId);
    },
    /**
     * Метод закрытия окна пройденного теста/опроса
     *
     * @returns {void}
     */
    closeThanks() {
      this.isAnswered = false;
      this.isEmmitedEventFromThanks = true;
      this.sendAnswer(this.slide.id);
    },

    handleChangeSlide(prevSlideQuiz) {
      if (!this.isEmmitedEventFromThanks) {
        this.sendAnswer(prevSlideQuiz.id);
      }
      if (this.isAnswered) {
        this.isAnswered = false;
      }
    },
    /**
     * Метод открытия окна пройденного теста/опроса и вызов метода завершения теста
     *
     * @returns {void}
     */
    timeOut() {
      this.isAnswered = true;
      this.finishTest({ testId: this.pollChain?.id, type: this.moduleType });
    },
  },
};
</script>

<style lang="less" scoped>
.poll-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;

  &__poll {
    display: flex;
    flex: 1;
    height: 500%;
  }

  &--show-thanks {
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &--rating {
    .poll {
      width: 100%;
    }
  }

  &__loader {
    margin: auto;
  }

  &__answer-button {
    margin: 32px auto 16px auto;
  }
}

.poll {
  max-width: 100%;
}

.timer-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.timer {
  width: 100px;
  margin: 0 auto;
}

.timer-fade-enter-active,
.timer-fade-leave-active {
  transition: opacity 0.6s;
}

.timer-fade-enter,
.timer-fade-leave-to {
  opacity: 0;
}
</style>
